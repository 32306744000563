// refactor from https://github.com/alinemorelli/react-gtm to use Next11 Script tag with afterInteractive strategy

import getConfig from "next/config";
import Script from "next/script";

export const { publicRuntimeConfig } = getConfig();

const warn = (s: string) => {
  console.warn("[react-gtm]", s);
};

interface SnippetsOptions {
  id: string;
  events?: { [key: string]: any };
  dataLayerName: string;
  preview?: string;
  auth?: string;
}

const Snippets = {
  tags: function ({
    id,
    events = {},
    dataLayerName,
    preview,
    auth,
  }: SnippetsOptions) {
    const gtm_auth = !auth ? "" : `&gtm_auth=${auth}`;
    const gtm_preview = !preview ? "" : `&gtm_preview=${preview}`;

    if (!id) warn("GTM Id is required");

    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
          events
        ).slice(1, -1)}});
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dl = dataLayerName != "dataLayer" ? "&l=" + dataLayerName : "";
    const gtmSrc = `https://www.googletagmanager.com/gtm.js?id=${id}${dl}&gtm_cookies_win=x`;

    return {
      iframe,
      script,
      gtmSrc,
    };
  },
};

const { gtmId, auth, preview } = publicRuntimeConfig.googleTagManagerConfig;
const snippets = Snippets.tags({
  id: gtmId,
  auth,
  preview,
  dataLayerName: "dataLayer",
});

interface TagManagerProps {
  dataLayer?: any;
  dataLayerName?: string;
}

interface WindowWithDynamicDataLayer extends Window {
  [key: string]: any;
}

const TagManager = {
  // eslint-disable-next-line react/display-name
  initialize: function () {
    const { iframe, script, gtmSrc } = snippets;

    return (
      <>
        <Script>{script}</Script>
        <Script strategy="afterInteractive" src={gtmSrc}></Script>

        <noscript
          dangerouslySetInnerHTML={{
            __html: iframe,
          }}
        ></noscript>
      </>
    );
  },
  dataLayer: function ({
    dataLayer,
    dataLayerName = "dataLayer",
  }: TagManagerProps) {
    const windowWithDynamicDataLayer = window as WindowWithDynamicDataLayer;

    if (windowWithDynamicDataLayer[dataLayerName]) {
      windowWithDynamicDataLayer[dataLayerName].push(dataLayer);
    }
  },
};

export default TagManager;
