export function getCountryForLocale(locale: string) {
  switch (locale) {
    case "en":
      return "gb";
    case "en-IE":
      return "ie";
    case "es":
      return "es";
    case "fr":
      return "fr";
    case "de":
      return "de";
    case "da":
      return "dk";
    case "sv":
      return "se";
    case "no":
      return "no";
    default:
      return "gb";
  }
}

export function getCountryUrlForLocale(locale: string) {
  switch (locale) {
    case "en":
      return { domain: "https://gudog.co.uk/blog" };
    case "en-IE":
      return { domain: "https://gudog.ie/blog" };
    case "es":
      return { domain: "https://gudog.com/blog" };
    case "fr":
      return { domain: "https://gudog.fr/blog" };
    case "de":
      return { domain: "https://gudog.de/blog" };
    case "da":
      return { domain: "https://gudog.dk/blog" };
    case "no":
      return { domain: "https://gudog.no/blog" };
    case "sv":
      return { domain: "https://gudog.se/blog" };
    default:
      return { domain: "https://gudog.co.uk/blog" };
  }
}
