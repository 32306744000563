/**
 * Receives an URL as string and returns
 * the canonical URL excluding all params in the query string
 * except `page`
 */
function canonical(url: string): string {
  const parsedUrl = new URL(url);
  // We only allow the page param in the canonical
  const page = parsedUrl.searchParams.get("page");

  return parsedUrl.origin + removeTrailingSlash(parsedUrl.pathname) + (page ? `?page=${page}` : "");
}

function removeTrailingSlash(url: string) {
  return url.endsWith("/") ? url.slice(0, -1) : url;
}

export default canonical;
