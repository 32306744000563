
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../faust.config";
import React from "react";
import { useRouter } from "next/router";
import { FaustProvider } from "@faustwp/core";
import "@faustwp/core/dist/css/toolbar.css";
import "../styles/global.scss";
import { ThemeProvider } from "styled-components";
import LocaleProvider from "../components/locale";
import theme from "../styles/theme";
import type { AppProps /*, AppContext */ } from "next/app";
import TagManager from "../lib/react-gtm";
import Head from "next/head";
import { getCountryForLocale, getCountryUrlForLocale } from "../lib/utils";
import canonical from "../utilities/canonical";
function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const locale = router.locale as string;
    const country = getCountryForLocale(locale);
    // in ireland, canonical should be uk to avoid duplicated content
    const isIE = country === "ie";
    const domain = getCountryUrlForLocale(!isIE ? locale : "en").domain;
    const url = `${domain}${router.asPath}`;
    const localeProvider = {
        country,
        locale,
    };
    return (<>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" name="viewport"/>

        <link rel="icon" href="/blog/images/favicon.ico"/>
        <link rel="stylesheet" href="/blog/fonts/Geomanist.css"/>
        <link rel="preload" href="/blog/fonts/Geomanist-Book.woff2" as="font" crossOrigin=""/>
        <link rel="preload" href="/blog/fonts/Geomanist-Medium.woff2" as="font" crossOrigin=""/>
        <link rel="preload" href="/blog/fonts/Geomanist-Regular.woff2" as="font" crossOrigin=""/>

        <link rel="canonical" href={canonical(url)}/>
      </Head>
      <FaustProvider pageProps={pageProps}>
        <ThemeProvider theme={theme}>
          <LocaleProvider value={localeProvider}>
            {TagManager.initialize()}
            <Component {...pageProps} key={router.asPath}/>
          </LocaleProvider>
        </ThemeProvider>
      </FaustProvider>
    </>);
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  