// const routes = require("./lib/routes.json");

const pages = {
  "*": ["common"],
};

// Object.entries(routes).forEach(function([name, route]) {
//   pages[name] = route.localeNamespaces;
// });

module.exports = {
  locales: ["es", "fr", "de", "en", "da", "sv", "no"],
  defaultLocale: "en",
  pages: pages,
  domains: [
    {
      domain: 'gudog.co.uk',
      defaultLocale: 'en',
    },
    {
      domain: 'gudog.fr',
      defaultLocale: 'fr',
    },
    {
      domain: 'gudog.com',
      defaultLocale: 'es',
    },
    {
      domain: 'gudog.es',
      defaultLocale: 'es-ES',
      locales: ['es']
    },
    {
      domain: 'gudog.de',
      defaultLocale: 'de',
    },
    {
      domain: 'gudog.ie',
      defaultLocale: 'en-IE',
      locales: ['en'],
    },
    {
      domain: 'gudog.dk',
      defaultLocale: 'da',
    },
    {
      domain: 'gudog.se',
      defaultLocale: 'sv',
    },
    {
      domain: 'gudog.no',
      defaultLocale: 'no',
    },
  ],
};
