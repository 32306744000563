import { lighten } from "polished";

const colors = {
  samoyedWhite: "#ffffff",
  pugYellow: "#F9B342",
  inuOrange: "#ED553B",
  mongrelBrown: "#5B2B2F",
  labradorBlack: "#010101",
  springGreen: "#3CB4A0",
  aquamarine: "#1F8C8D",
  prusianBlue: "#033243",
  lightGray: "#EBEBEB",
  gray: "#DDDDDD",
  darkGray: "#9A9A9A",
  errorRed: "#B31412",
  veryDarkGray: "#181818",
  successGreen: "#6DD400",
};

//Alias
colors.primary = colors.inuOrange;
colors.black = colors.labradorBlack;
colors.background = colors.samoyedWhite;

// Default breakpoints borrwed from Bootstrap
// https://getbootstrap.com/docs/4.3/layout/overview/
export const breakpoints = {
  small: 576, // mobile devices
  medium: 768, // ipad, ipad pro, ipad mini, etc
  large: 992, // smaller laptops
  xlarge: 1200, // laptops and desktops
};

const theme = {
  breakpoints: Object.values(breakpoints).map((val) => `${val}px`),
  colors: { ...colors },
  fonts: {
    body: "Geomanist",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 40, 48, 64, 96, 136],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 500,
    // Font-face mapping for weights
    book: 300,
    regular: 400,
    medium: 600,
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 96],
  text: {
    h1: {
      fontWeight: 600,
      fontSize: "40px",
      lineHeight: 48 / 40,
    },
    h2: {
      fontSize: "30px",
      lineHeight: "36px",
      fontWeight: 600,
    },
    h3: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: 24 / 24,
    },
    h4_highlight: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 24 / 16,
    },
    h4: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 24 / 16,
    },
    h5: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 16 / 14,
    },
    note: {
      fontWeight: 400,
      fontSize: "8px",
      lineHeight: 8 / 8,
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  buttons: {
    large: {
      fontSize: "18px",
      lineHeight: "24px",
      px: 2,
      py: 0,
      minHeight: "48px",
    },
    primary: {
      cursor: "pointer",
      fontWeight: 600,
      color: "background",
      bg: "primary",
      borderRadius: "default",
      "&:hover": {
        bg: lighten(0.1, colors.primary),
      },
      "&:focus": {
        outline: "none",
      },
      "&:disabled": {
        bg: lighten(0.2, colors.primary),
      },
    },
    secondary: {
      variant: "buttons.primary", //  Inherits from prev variant
      bg: "springGreen",
      "&:hover": {
        bg: lighten(0.2, colors.springGreen), // TODO add transparency
      },
      "&:disabled": {
        bg: lighten(0.2, colors.springGreen),
      },
    },
    terciary: {
      variant: "buttons.primary",
      color: "black",
      bg: "transparent",
      border: "default",
      "&:hover": {
        borderColor: "black",
      },
      "&:disabled": {
        color: lighten(0.2, "black"),
        borderColor: "default",
      },
    },
    link: {
      variant: "buttons.terciary",
      border: "0px solid",
      color: "inuOrange",
      fontWeight: 500,
      "&:hover": {
        bg: "transparent",
      },
    },
  },
  container: {
    mx: ["16px", "16px", "120px"],
    my: ["40px", "40px", "24px"],
    alignItems: ["center", "center", "flex-start"],
  },
  mainTitle: {
    fontSize: "36px !important",
    lineHeight: "40px !important",
    fontWeight: "500 !important",
    textAlign: "left",
  },
  title: {
    fontSize: "24px !important",
    lineHeight: "32px !important",
    fontWeight: "500 !important",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  paragraph: {
    fontSize: "18px",
    lineHeight: "30px",
    fontWeight: 400,
    my: 3
  },
  description: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "darkGray",
    whiteSpace: "pre-line",
    mb: 0,
  },
  label: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  colToRow: {
    flexDirection: ["column", "column", "row"],
  },
  rowToCol: {
    flexDirection: ["row", "row", "column"],
  },
  fullToHalf: {
    width: ["100%", "100%", "50%"],
  },
  halfToFull: {
    width: ["50%", "50%", "100%"],
  },
  input: {
    borderColor: "lightGray",
    borderRadius: "6px",
    p: "12px",
    "::placeholder": {
      color: "lightGray",
    },
  },
};

export default theme;
